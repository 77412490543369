
<template>
    <b-container fluid>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{ $t('barc_config.circular_information') }}</h4>
                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loadingState">
                            <b-row>
                                <b-col md="12" class="table-responsive">
                                    <b-table thead-class="table_head" :emptyText="$t('globalTrans.noDataFound')" show-empty striped bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                                        <template v-slot:cell(index)="data">
                                            {{ $n(data.index + pagination.slOffset) }}
                                        </template>
                                        <template v-slot:cell(deadline)="data">
                                          {{ $d(new Date(data.item.deadline)) }}
                                        </template>
                                        <template v-slot:cell(circular_date)="data">
                                          {{ $d(new Date(data.item.circular_date)) }}
                                        </template>
                                        <template v-slot:cell(status)="data">
                                            <span class="badge badge-success" v-if="data.item.status == 1">{{$t('globalTrans.active')}}</span>
                                            <span class="badge badge-danger" v-else>{{$t('globalTrans.inactive')}}</span>
                                        </template>
                                        <template v-slot:cell(action)="data">
                                            <a href="javascript:" class="btn_table_action table_action_edit" title="Apply" size="sm" @click="apply(data.item)">
                                              <i class="fa fa-paper-plane" aria-hidden="true"></i>
                                            </a>
                                        </template>
                                    </b-table>
                                    <b-pagination
                                        align="center"
                                        v-model="pagination.currentPage"
                                        :per-page="pagination.perPage"
                                        :total-rows="pagination.totalRows"
                                        @input="searchData"
                                        />
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { /* checkUniversityCourse, */ circularList } from '../../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import flatpickr from 'flatpickr'
import { mapGetters } from 'vuex'

export default {
  mixins: [ModalBaseMasterList],
  components: {
  },
  data () {
    return {
      search: {
        circular_form_date: '',
        circular_to_date: ''
      },
      rows: []
    }
  },
  computed: {
     ...mapGetters({
      authUser: 'Auth/authUser'
    }),
    publicationType: function () {
      return this.$store.state.incentiveGrant.commonObj.publicationTypeList.filter(item => item.status === 1)
    },
    formTitle () {
      return (this.editItemId === 0) ? this.$t('barc_config.circular_information_entry') : this.$t('barc_config.circular_information_modify')
    },
    columns () {
      const labels = [
        { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
        { label: this.$t('barc_config.circular_name'), class: 'text-center' },
        { label: this.$t('barc_config.memo_no'), class: 'text-center' },
        { label: this.$t('barc_config.circular_date'), class: 'text-center' },
        { label: this.$t('barc_config.deadline'), class: 'text-center' },
        { label: this.$t('globalTrans.status'), class: 'text-center' },
        { label: this.$t('globalTrans.action'), class: 'text-center' }
      ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'circular_name_bn' },
          { key: 'memo_no' },
          { key: 'circular_date' },
          { key: 'deadline' },
          { key: 'status' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'circular_name' },
          { key: 'memo_no' },
          { key: 'circular_date' },
          { key: 'deadline' },
          { key: 'status' },
          { key: 'action' }
        ]
      }

      return labels.map((item, index) => {
        return Object.assign(item, keys[index])
      })
    }
  },
  mounted () {
    flatpickr('#datepicker', {})
  },
  watch: {
  },
  created () {
    this.loadData()
  },
  methods: {
    searchData () {
      this.loadData()
    },
    apply (item) {
      this.$router.push(`/grant-panel/application-form-main?circular_id=${item.id}`)
      /* this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      const params = Object.assign({}, { user_id: this.authUser.user_id, circular_id: item.id })
      RestApi.getData(incentiveGrantServiceBaseUrl, checkUniversityCourse, params).then(response => {
        if (response.data.length) {
          this.$toast.error({
            title: this.$t('globalTrans.error'),
            message: this.$t('dealer_panel.slot_booking_msg'),
            color: '#fff000'
          })
        } else if (response.circularWiseAppCheck.length) {
          this.$toast.error({
            title: this.$t('globalTrans.error'),
            message: this.$t('dealer_panel.already_apply'),
            color: '#fff000'
          })
        } else {
          this.$router.push(`/grant-panel/application-form-main?circular_id=${response.circular_id}`)
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      }) */
    },
    loadData () {
      var typeId = ''
      if (this.search.type_name_id) {
        typeId = this.search.type_name_id.value
      }
      const params = Object.assign({}, this.search, { type_name_id: typeId, page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      RestApi.getData(incentiveGrantServiceBaseUrl, circularList, params).then(response => {
        if (response.success) {
          this.$store.dispatch('setList', response.data.data)
          this.paginationData(response.data)
        } else {
          this.$store.dispatch('setList', [])
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    }
  }
}
</script>
